import 'tom-select/dist/esm/plugins/checkbox_options/plugin';

import TomSelect from 'tom-select/dist/esm/tom-select';
import { hash_key } from 'tom-select/dist/esm/utils';
import { getDom } from './common';

// This plugin replaces the checkbox plugin of the library, implementing new template and
// functionality of checkbox. This one is used for the multiple selection.
TomSelect.define('checkbox', function () {
    this.require('checkbox_options');

    this.hook('after', 'setupTemplates', () => {
        setTimeout(() => {
            const orig_render_option = this.settings.render.option;

            this.settings.render.option = (data, escape_html): string | HTMLElement => {
                const rendered = getDom(orig_render_option.call(this, data, escape_html));
                const hashed = hash_key(data[this.settings.valueField]);
                const isChecked = hashed && this.items.indexOf(hashed) > -1;

                return /* html */ `
                    <div class="check-box-wrapper">
                        <label class="check-box-select">
                            <input type="checkbox" ${isChecked ? 'checked' : ''} />
                            <span>${rendered.innerText}</span>
                        </label>
                    </div>
                `;
            };
        });
    });

    const updateCheckbox = (value: string): void => {
        const option: HTMLElement = this.getOption(value);
        if (option) {
            setTimeout(() => {
                const checkbox = option.querySelector('input');
                if (option.classList.contains('selected')) {
                    checkbox.checked = true;
                } else {
                    checkbox.checked = false;
                }
            }, 1);
        }
    };

    this.on('item_remove', updateCheckbox.bind(this));
    this.on('item_add', updateCheckbox.bind(this));
});
