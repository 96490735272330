// import * as DOMPurify from 'dompurify';
import { FxRenderer } from '@finantix/core';
import type { FxMailViewer } from './fx-mail-viewer';
import type { FxEmailAttachment, FxEmailAddress } from '../shared/models/fx-mail.model';
import '../../fx-spinner/fx-spinner';
import moment from 'moment';

/**
 * FxMailViewer Renderer class
 *
 * @export
 * @class FxMailViewerRenderer
 * @extends {FxRenderer}
 */
export class FxMailViewerRenderer extends FxRenderer {
    private cidAttachments: FxEmailAttachment[] = [];
    /**
     * Creates an instance of FxSelectRenderer.
     *
     * @memberof FxSelectRenderer
     */
    public constructor() {
        super();
    }

    /**
     * Renderer method
     *
     * @param {string} context
     * @param {FxMailViewer} container
     * @memberof FxMailViewerRenderer
     */
    public render(context: string, container: FxMailViewer): void {
        const subjectLabel = container.subjectLabel;
        const toLabel = container.toLabel;
        const fromLabel = container.fromLabel;
        const ccLabel = container.ccLabel;
        const bccLabel = container.bccLabel;
        const dateSentLabel = container.dateSentLabel;
        const attachmentDownloadAllLabel = container.attachmentDownloadAllLabel;
        const attachmentNoticeTemplate = container.attachmentNoticeTemplate;
        const attachmentInlineTemplate = container.attachmentInlineTemplate;

        if (container.model) {
            const toAddresses = this.parseEmailAddresses(container.model.toAddress || []);
            const fromAddresses = this.parseEmailAddresses(container.model.fromAddress || []);
            const ccAddresses = this.parseEmailAddresses(container.model.CCAddress || []);
            const bccAddresses = this.parseEmailAddresses(container.model.BCCAddress || []);

            // Body content
            container.innerHTML = `
                <div class="mail-viewer">
                    <fx-spinner style="display: none" class="full-page small"></fx-spinner>
                    <div class="mail-viewer-header">
                        <div class="mail-viewer-header-info">
                            <div class="mail-viewer-header-info-from">
                                <label>${fromLabel}</label>${fromAddresses}
                            </div>
                            <div class="mail-viewer-header-info-from">
                                <label>${toLabel}</label>${toAddresses}
                            </div>
                            ${
                                ccAddresses !== ''
                                    ? `
                                <div class="mail-viewer-header-info-from">
                                <label>${ccLabel}</label>${ccAddresses}
                                </div>`
                                    : ''
                            }
                            ${
                                bccAddresses !== ''
                                    ? `
                                <div class="mail-viewer-header-info-from">
                                <label>${bccLabel}</label>${bccAddresses}
                                </div>`
                                    : ''
                            }
                            <div class="mail-viewer-header-info-subject">
                                <label>${subjectLabel}</label>${container.model.subject}
                            </div>
                            <div class="mail-viewer-header-info-date-time">
                                <label>${dateSentLabel}</label>${this.formatDateTime(
                container.model.receivedDateTime,
                container.dateFormat
            )}
                            </div>
                        </div>
                    </div>
                </div>
            `;

            // Attachments box
            if (container.model.attachments?.length > 0) {
                const attachmentsListLayout: HTMLElement = document.createElement('div');
                attachmentsListLayout.className = 'mail-viewer-header-attachments';

                const attachmentsToDownload = container.model.attachments.filter(
                    (el) => !el.isInline
                );

                // Attachments available
                container.model.attachments.forEach((attachment) => {
                    if (attachment.isInline === true) {
                        // Special attachments for CID images within body email
                        this.cidAttachments.push(attachment);
                    } else {
                        // Standard attachments
                        const attachmentsItem: HTMLElement = document.createElement('a');
                        attachmentsItem.className = 'mail-viewer-header-attachments-item';
                        attachmentsItem.innerHTML = /* html */ `
                            <span class="attachment-name">${attachment.name}</span>
                            <span class="attachment-size">${this.formatBytes(
                                attachment.size
                            )}</span>
                        `;

                        if (container.attachmentDownload) {
                            // If download is enabled, add click event
                            attachmentsItem.addEventListener('click', () => {
                                // Show spinner
                                container.showLoading();
                                container.onAttachmentClickCallback(
                                    attachment,
                                    false,
                                    container.onResolvedDownloadAttachment.bind(container)
                                );
                            });
                        }

                        // Append attachment item to the list
                        attachmentsListLayout.appendChild(attachmentsItem);
                    }
                });

                // Attachments download all row
                const attachmentDownloadAllLayout: HTMLElement = document.createElement('div');
                attachmentDownloadAllLayout.className = 'mail-viewer-header-attachments-download';
                attachmentDownloadAllLayout.innerHTML = `<span>${attachmentDownloadAllLabel}</span>`;

                // Attachments notice
                const attachmentNoticeLayout: HTMLElement = document.createElement('div');
                attachmentNoticeLayout.className = 'mail-viewer-header-attachments-notice';
                attachmentNoticeLayout.innerHTML = attachmentNoticeTemplate;

                if (attachmentsToDownload.length > 0) {
                    // Append attachments list layout
                    container
                        .getElementsByClassName('mail-viewer-header')[0]
                        .appendChild(attachmentsListLayout);
                }

                if (attachmentsToDownload.length > 1) {
                    if (container.attachmentDownload) {
                        // Add click event
                        attachmentDownloadAllLayout.firstChild.addEventListener('click', () => {
                            // Show spinner
                            container.showLoading();
                            container.onAttachmentClickCallback(
                                undefined,
                                true,
                                container.onResolvedDownloadAttachment.bind(container)
                            );
                        });
                    }

                    // Append attachments download all layout
                    container
                        .getElementsByClassName('mail-viewer-header')[0]
                        .appendChild(attachmentDownloadAllLayout);
                }

                if (!container.attachmentDownload) {
                    // Append attachments notice layout
                    container
                        .getElementsByClassName('mail-viewer-header')[0]
                        .appendChild(attachmentNoticeLayout);
                }
            }

            // Append body content
            if (container.model.body) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(container.model.body, 'text/html');

                // IMPORTANT: Parse body to replace CID images with the real b64 content from INLINE attachments
                if (this.cidAttachments.length > 0 && container.model.body.length > 0) {
                    const imageLinks = Array.from(doc.getElementsByTagName('img'));

                    imageLinks.forEach((imageLink) => {
                        const src = imageLink.getAttribute('src');

                        if (src !== null && src !== undefined && src.includes('cid:')) {
                            const cidInfo = src.split('cid:');
                            const useImage = cidInfo.length === 2;

                            if (useImage) {
                                const fileName = cidInfo[1].split('@')[0];
                                const fileIndex = this.cidAttachments.findIndex(
                                    (item) => item.name === fileName && item.isInline === true
                                );

                                if (fileIndex > -1) {
                                    if (
                                        this.cidAttachments[fileIndex].contentBytes &&
                                        this.cidAttachments[fileIndex].contentBytes.length > 0
                                    ) {
                                        imageLink.setAttribute(
                                            'src',
                                            `data:${this.cidAttachments[fileIndex].contentType};base64,` +
                                                this.cidAttachments[fileIndex].contentBytes
                                        );
                                    } else if (
                                        container.attachmentInlineTemplate &&
                                        container.attachmentInlineTemplate.length > 0
                                    ) {
                                        this.replaceInlineAttachmentWithPlaceholder(
                                            imageLink,
                                            container
                                        );
                                    }
                                } else {
                                    if (
                                        container.attachmentInlineTemplate &&
                                        container.attachmentInlineTemplate.length > 0
                                    ) {
                                        this.replaceInlineAttachmentWithPlaceholder(
                                            imageLink,
                                            container
                                        );
                                    }
                                }
                            }
                        }
                    });
                }

                // Append body when available
                const bodyElement: HTMLElement = document.createElement('div');
                bodyElement.className = 'mail-viewer-body';
                bodyElement.innerHTML = doc.documentElement.innerHTML;
                container.getElementsByClassName('mail-viewer')[0].appendChild(bodyElement);

                // Sanitized version
                // container.getElementsByClassName('mail-viewer-body')[0].innerHTML = this.purifyHtmlContent(body);
            }
        } else {
            container.innerHTML =
                '<div class="mail-viewer"><fx-spinner style="display: none" class="full-page small"></fx-spinner></div>';
        }
    }

    /**
     * Fromat bytes
     *
     * @private
     * @param {*} bytes
     * @param {number} [decimals=2]
     * @return {*}
     * @memberof FxMailViewerRenderer
     */
    private formatBytes(bytes, decimals = 2): string {
        if (!+bytes) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    /**
     * Combine multiple address values
     *
     * @private
     * @param {FxEmailAddress[]} addresses
     * @return {*}  {string}
     * @memberof FxMailViewerRenderer
     */
    private parseEmailAddresses(addresses: FxEmailAddress[]): string {
        const addressesList = [];
        addresses.forEach((recipient) => {
            addressesList.push(this.combineEmailAddressAndName(recipient.address, recipient.name));
        });
        return addressesList.join(', ');
    }

    /**
     * Combine email address and email name
     *
     * @private
     * @param {string} [address='']
     * @param {string} [name='']
     * @return {*}  {string}
     * @memberof FxMailViewerRenderer
     */
    private combineEmailAddressAndName(address = '', name = ''): string {
        if (address !== '') {
            address = `(${address})`;
        }
        return `${name} ${address}`;
    }

    /**
     * Sanitize html content as parameter
     *
     * @private
     * @param {*} htmlContent
     * @return {*}  {string}
     * @memberof FxMailViewerRenderer
     */
    // private purifyHtmlContent(htmlContent): string {
    //     return DOMPurify.sanitize(htmlContent);
    // }

    /**
     * Format date time with moment.js
     *
     * @private
     * @param {string} date
     * @param {string} format
     * @memberof FxMailListRenderer
     */
    private formatDateTime = (date: string, format: string): string => {
        // return moment(date).format(format);
        return moment(new Date(date)).format(format);
    };

    /**
     * Replace inline img tag with an html template provided as paramenter
     *
     * @private
     * @param {*} imageLink
     * @param {FxMailViewer} container
     * @memberof FxMailViewerRenderer
     */
    private replaceInlineAttachmentWithPlaceholder(imageLink, container: FxMailViewer): void {
        // Replace broken image with the placeholder provided
        const placeholder = document.createElement('div');
        placeholder.innerHTML = container.attachmentInlineTemplate.trim();

        imageLink.parentNode.replaceChild(placeholder.firstChild, imageLink);
    }
}
